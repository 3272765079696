.video-dialog-box {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 99;
  background-color: #000;
  overflow: hidden;
}

[data-dpr="1"] .video-dialog-box {
  max-width: 640px;
  min-width: 320px;
}

[data-dpr="2"] .video-dialog-box {
  max-width: 1280px;
  min-width: 640px;
}

[data-dpr="3"] .video-dialog-box {
  max-width: 1920px;
  min-width: 960px;
}

.video-dialog-box .video-contain {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #000;
}

.video-dialog-box .dialog-close-btn {
  top: 0;
  position: absolute;
  width: 100%;
  height: 2.133333rem;
  background: -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, 0)), to(rgba(0, 0, 0, 0.45)));
  background: -webkit-linear-gradient(bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.45) 100%);
  background: -moz-linear-gradient(bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.45) 100%);
  background: linear-gradient(360deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.45) 100%);
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
  -webkit-transition: 0.25s;
  -moz-transition: 0.25s;
  transition: 0.25s;
}

.video-dialog-box .dialog-close-btn.hidden {
  opacity: 0;
  -webkit-transform: translateY(-2.133333rem);
  -moz-transform: translateY(-2.133333rem);
  transform: translateY(-2.133333rem);
}

.video-dialog-box .dialog-close-btn i {
  font-size: 0.426667rem;
  margin-left: 0.426667rem;
  color: #fff;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  transform: rotate(180deg);
}

.video-dialog-box .drag-time-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  z-index: 1;
  top: 0;
  left: 0;
}

.video-dialog-box .drag-time-bg .drag-time-box {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -moz-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.video-dialog-box .drag-time-bg .drag-time-box .left-time {
  font-size: 0.64rem;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 0.893333rem;
  text-shadow: 0 0.026667rem 0.053333rem rgba(0, 0, 0, 0.5);
}

.video-dialog-box .drag-time-bg .drag-time-box .middle {
  color: rgba(255, 255, 255, 0.6);
  font-size: 0.32rem;
  margin: 0 0.266667rem;
  line-height: 0.666667rem;
}

.video-dialog-box .drag-time-bg .drag-time-box .right-time {
  color: rgba(255, 255, 255, 0.6);
  font-size: 0.64rem;
  font-weight: 600;
  line-height: 0.893333rem;
  text-shadow: 0 0.026667rem 0.053333rem rgba(0, 0, 0, 0.5);
}

.video-dialog-box .control-btn-box {
  bottom: 0;
  position: absolute;
  width: 100%;
  height: 2.573333rem;
  background: -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, 0.45)), to(rgba(0, 0, 0, 0)));
  background: -webkit-linear-gradient(bottom, rgba(0, 0, 0, 0.45) 0%, rgba(0, 0, 0, 0) 100%);
  background: -moz-linear-gradient(bottom, rgba(0, 0, 0, 0.45) 0%, rgba(0, 0, 0, 0) 100%);
  background: linear-gradient(360deg, rgba(0, 0, 0, 0.45) 0%, rgba(0, 0, 0, 0) 100%);
  opacity: 1;
  -webkit-transition: 0.25s;
  -moz-transition: 0.25s;
  transition: 0.25s;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  transform: translateY(0);
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0.746667rem 0.426667rem 0.426667rem;
}

.video-dialog-box .control-btn-box.hidden {
  opacity: 0;
  -webkit-transform: translateY(2.573333rem);
  -moz-transform: translateY(2.573333rem);
  transform: translateY(2.573333rem);
}

.video-dialog-box .control-btn-box .progress-box {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.video-dialog-box .control-btn-box .progress-box .progress-current-time {
  font-size: 0.32rem;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 0.44rem;
}

.video-dialog-box .control-btn-box .progress-box .progress-all-time {
  font-size: 0.32rem;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 0.44rem;
}

.video-dialog-box .control-btn-box .progress-box .video-progress-contain {
  height: 0.053333rem;
  background: rgba(255, 255, 255, 0.4);
  -webkit-border-radius: 0.026667rem;
  border-radius: 0.026667rem;
  position: relative;
  -webkit-box-flex: 1;
  -webkit-flex: 1 1;
  -moz-box-flex: 1;
  -ms-flex: 1 1;
  flex: 1 1;
  margin: 0 0.213333rem;
}

.video-dialog-box .control-btn-box .progress-box .video-progress-contain .video-progress-touch {
  position: absolute;
  width: 100%;
  left: 0;
  height: 0.453333rem;
  top: -0.2rem;
  z-index: 1;
}

.video-dialog-box .control-btn-box .progress-box .video-progress-contain .video-progress {
  -webkit-border-radius: 0.026667rem;
  border-radius: 0.026667rem;
  height: 0.053333rem;
  background: #ffffff;
  position: absolute;
  left: 0;
  top: 0;
}

.video-dialog-box .control-btn-box .progress-box .video-progress-contain .video-progress .circle {
  width: 0.213333rem;
  height: 0.213333rem;
  background: #ffffff;
  position: absolute;
  right: 0;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  top: -0.08rem;
}

.video-dialog-box .control-btn-box .box-footer {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-top: 0.426667rem;
}

.video-dialog-box .control-btn-box .box-footer .left-btn-list i {
  font-size: 0.533333rem;
  margin-right: 0.64rem;
  color: #fff;
  cursor: pointer;
}

.video-dialog-box .control-btn-box .box-footer .right-btn-list {
  text-align: right;
}

.video-dialog-box .control-btn-box .box-footer .right-btn-list i {
  font-size: 0.533333rem;
  color: #fff;
  cursor: pointer;
}

.video-dialog-box .video-loading {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 1;
}

.video-dialog-box .video-loading img {
  width: 1.066667rem;
  height: 1.066667rem;
  -webkit-animation: rotate360 1s infinite linear;
  -moz-animation: rotate360 1s infinite linear;
  animation: rotate360 1s infinite linear;
}

@-webkit-keyframes rotate360 {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-moz-keyframes rotate360 {
  0% {
    -moz-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -moz-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotate360 {
  0% {
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}