.home-training-top-banner-mobile {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  background-color: #F9F9FB;
  position: relative;
}

.home-training-top-banner-mobile .home-training-top-banne-swiper {
  width: 100%;
  height: 10rem;
  cursor: pointer;
  position: relative;
}

.home-training-top-banner-mobile .home-training-top-banne-swiper .home-training-top-banne-swiper-img {
  width: 100%;
  height: 100%;
}

.home-training-top-banner-mobile .home-training-top-banne-swiper .home-training-top-banne-swiper-img img {
  width: 100%;
  height: 100%;
}

.home-training-top-banner-mobile .home-training-top-banne-swiper .swiper-pagination {
  position: absolute;
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  bottom: 0.32rem;
  z-index: 10;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.home-training-top-banner-mobile .home-training-top-banne-swiper .swiper-pagination .swiper-pagination-bullet {
  width: 0.213333rem;
  height: 0.08rem;
  background: rgba(0, 0, 0, 0.8);
  margin: 0 0.053333rem;
  -webkit-border-radius: 1.08rem;
  border-radius: 1.08rem;
}

.home-training-top-banner-mobile .home-training-top-banne-swiper .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: #F5333F;
  opacity: 1;
}

.home-training-top-banner-mobile .home-training-top-banner-mobile-brand {
  width: 9.146667rem;
  height: 1.706667rem;
  background: -webkit-linear-gradient(45deg, rgba(245, 243, 243, 0.6) 0%, rgba(255, 255, 255, 0.9) 100%);
  background: -moz-linear-gradient(45deg, rgba(245, 243, 243, 0.6) 0%, rgba(255, 255, 255, 0.9) 100%);
  background: linear-gradient(45deg, rgba(245, 243, 243, 0.6) 0%, rgba(255, 255, 255, 0.9) 100%);
  -webkit-box-shadow: 0 0.213333rem 0.64rem 0 rgba(240, 211, 201, 0.62);
  box-shadow: 0 0.213333rem 0.64rem 0 rgba(240, 211, 201, 0.62);
  -webkit-border-radius: 0.213333rem;
  border-radius: 0.213333rem;
  border: 0.026667rem solid #FFFFFF;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: absolute;
  bottom: 0.826667rem;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  transform: translateX(-50%);
  z-index: 5;
}

.home-training-top-banner-mobile .home-training-top-banner-mobile-brand .brand-left {
  padding-left: 0.426667rem;
}

.home-training-top-banner-mobile .home-training-top-banner-mobile-brand .brand-left .brand-title {
  font-size: 0.426667rem;
  font-weight: 500;
  color: #6E2D27;
  line-height: 0.693333rem;
}

.home-training-top-banner-mobile .home-training-top-banner-mobile-brand .brand-left .brand-text {
  font-weight: 400;
  font-size: 0.373333rem;
  color: #6E2D27;
  line-height: 0.586667rem;
}

.home-training-top-banner-mobile .home-training-top-banner-mobile-brand .brand-right {
  width: 3.426667rem;
  height: 1.066667rem;
  background: -webkit-linear-gradient(45deg, #F5333F 0%, #FB7162 100%);
  background: -moz-linear-gradient(45deg, #F5333F 0%, #FB7162 100%);
  background: linear-gradient(45deg, #F5333F 0%, #FB7162 100%);
  -webkit-border-radius: 1.32rem;
  border-radius: 1.32rem;
  margin-right: 0.32rem;
  position: relative;
}

.home-training-top-banner-mobile .home-training-top-banner-mobile-brand .brand-right::before {
  content: '1\5206\949F\4E86\89E3';
  position: absolute;
  font-size: 0.426667rem;
  color: #fff;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0.32rem;
}

.home-training-top-banner-mobile .home-training-top-banner-mobile-brand .brand-right::after {
  content: '';
  position: absolute;
  background: url(https://img.qlchat.com/qlLive/activity/image/9KLPI3R9-PDKG-GP2Z-1709099386745-UWL7A32I89PI.png) no-repeat;
  -webkit-background-size: auto 100%;
  background-size: auto 100%;
  width: 0.64rem;
  height: 0.64rem;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 0.32rem;
}